<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <span class="ddh-highlight-text" v-html="DOMPurify(content)" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import DOMPurify from '@/utils/DOMPurify';

const props = defineProps<{
  text: string | number | null;
  query: string | number;
}>();

const content = computed(() => {
  if (!props.query) return `${props.text}`;

  const shieldingQuery = `${props.query}`.replace(
    /[.*+?^${}()|[\]\\]/g,
    '\\$&',
  );

  const regExp = new RegExp(shieldingQuery, 'ig');

  return `${props.text}`.replace(
    regExp,
    '<span class="ddh-highlight-text__match bg-primary-lighten-3">$&</span>',
  );
});
</script>
<style scoped lang="scss">
.ddh-highlight-text {
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>
