<template>
  <v-table :class="rootClassNames">
    <template #wrapper>
      <div
        ref="wrapperCloneElRef"
        class="ddh-table__wrapper ddh-table__wrapper--clone v-table__wrapper"
      >
        <table>
          <DdhTableHead
            ref="tableHeadCloneRef"
            class="ddh-table__head"
            :header-cells="headerCells"
            :total-cells="totalCells"
            :sorting-item="sortingItem"
            :loading="loading"
            :total-is-shown="totalIsShown"
            @toggle-sorting-item="toggleSortingItem"
          />
        </table>
      </div>
      <div
        ref="wrapperElRef"
        class="ddh-table__wrapper ddh-table__wrapper--hidden-scroll v-table__wrapper"
        :style="{
          height: convertToUnit(props.height),
        }"
      >
        <table ref="tableElRef">
          <DdhTableHead
            ref="tableHeadRef"
            class="ddh-table__head ddh-table__head--original"
            :header-cells="headerCells"
            :total-cells="totalCells"
            :sorting-item="sortingItem"
            :loading="loading"
            :total-is-shown="totalIsShown"
            @toggle-sorting-item="toggleSortingItem"
          />
          <tbody class="ddh-table__body">
            <template v-if="rows.length">
              <tr
                v-if="renderZone[0] !== 0"
                :key="'render-top' + renderZone[0]"
                v-intersect="progressRenderTop"
                :style="`height: ${renderZone[0] * CELL_HEIGHT}px `"
              />
              <template v-for="idx in renderZoneIndexes" :key="idx + 'data'">
                <tr
                  v-if="rows[idx]"
                  class="ddh-table__row ddh-table__row--body"
                  :class="classRowEven(idx)"
                >
                  <td
                    v-for="cell in rows[idx].cells"
                    :key="cell.prop"
                    class="ddh-table__cell ddh-table__cell--body"
                    :class="cell.className"
                    :style="cell.style"
                  >
                    <slot
                      :name="`cell-${cell.prop}`"
                      :index="idx"
                      :item="rows[idx].data"
                      :placeholder="cell.placeholder"
                    >
                      {{ cell.renderValue }}
                    </slot>
                  </td>
                </tr>
              </template>
              <tr
                v-if="renderZone[1] < rows.length"
                :key="`bottomRender` + renderZone[1]"
                v-intersect="progressRenderBottom"
                :style="`height: ${
                  (rows.length - renderZone[1]) * CELL_HEIGHT
                }px `"
              />
            </template>
          </tbody>
        </table>
      </div>
    </template>
    <template #bottom>
      <slot v-if="!data.length" name="stub">
        <div class="ddh-table__stub d-flex justify-center align-center">
          {{ loading ? loadingText : placeholder }}
        </div>
      </slot>
      <div
        v-else-if="fetching"
        class="ddh-table__stub d-flex justify-center align-center"
      >
        <v-progress-circular size="24" color="primary" indeterminate />
      </div>
      <div v-intersect="onBottomIntersect" />
    </template>
  </v-table>
</template>

<script lang="ts" setup generic="DataItem extends DdhTableDataItem">
import { ref, computed, watch, onMounted } from 'vue';

import { get } from 'lodash';

import { useClassNames } from '../../composables';

import { numberFormat } from '../../utils';

import { replaceSpaceToNonBreakingSpace, convertToUnit } from './utils';

import DdhTableHead from './DdhTableHead.vue';

import {
  DdhTableColumnType,
  type DdhTableColumn,
  type DdhTableCell,
  // Тут нет ошибки, просто линтер не понимает, что тип используется в дженерике выше
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type DdhTableDataItem,
  type DdhTableSortingItem,
  DdhTableSortingOrder,
} from './types';

const props = withDefaults(
  defineProps<{
    columns: DdhTableColumn[];
    data: DataItem[];
    sortingItem?: DdhTableSortingItem | null;
    defaultSortingOrder?: `${DdhTableSortingOrder}`;
    height?: string | number;
    stickyOffset?: number;
    placeholder?: string;
    cellPlaceholder?: string;
    loadingText?: string;
    loading?: boolean;
    fetching?: boolean;
  }>(),
  {
    sortingItem: null,
    defaultSortingOrder: DdhTableSortingOrder.Asc,
    height: undefined,
    stickyOffset: 0,
    placeholder: 'Нет данных',
    cellPlaceholder: '—',
    loadingText: 'Загрузка...',
    loading: false,
    fetching: false,
  },
);

const emit = defineEmits<{
  (e: 'update:sorting-item', val: DdhTableSortingItem | null): void;
  (e: 'bottomIntersect', val: boolean): void;
}>();

const renderStep = 4;
const renderZone = ref([0, 25]);

const renderZoneIndexes = computed(() => {
  return [...Array(renderZone.value[1] + 1 - renderZone.value[0]).keys()].map(
    (i) => renderZone.value[0] + i,
  );
});

const CELL_HEIGHT = 56;
const cssCellHeight = `${CELL_HEIGHT}px`;

onMounted(() => {
  renderZone.value[1] = Math.round(window.innerHeight / CELL_HEIGHT) + 10;
});

const progressRenderTop = (isIntersecting: boolean) => {
  if (!isIntersecting) return;

  renderZone.value[0] -= renderStep;
  renderZone.value[1] -= renderStep;
};

const progressRenderBottom = (isIntersecting: boolean) => {
  if (!isIntersecting) return;

  renderZone.value[0] += renderStep;
  renderZone.value[1] += renderStep;
};

const tableHeadRef = ref<InstanceType<typeof DdhTableHead> | null>(null);
const tableHeadCloneRef = ref<InstanceType<typeof DdhTableHead> | null>(null);

const wrapperElRef = ref<HTMLElement | null>(null);
const wrapperCloneElRef = ref<HTMLElement | null>(null);

const tableElRef = ref<HTMLElement | null>(null);

const stickyOffsetCss = computed(() => `${props.stickyOffset}px`);

const recalcTableHeadCloneWidth = () => {
  if (!wrapperElRef.value || !tableHeadRef.value || !tableHeadCloneRef.value) {
    return;
  }

  const wrapperEl = wrapperElRef.value;

  const tableHeadEl = tableHeadRef.value.$el as HTMLTableSectionElement;

  const tableHeadCloneEl = tableHeadCloneRef.value
    .$el as HTMLTableSectionElement;

  const headCellEls = tableHeadEl.querySelectorAll('th');
  const clonedHeadCellEls = tableHeadCloneEl.querySelectorAll('th');

  headCellEls.forEach((headCellEl, idx) => {
    if (!clonedHeadCellEls[idx]) {
      return;
    }

    const headCellElRect = headCellEl.getBoundingClientRect();
    const headCellElWidth = headCellElRect.width.toFixed(2);

    clonedHeadCellEls[idx].style.width =
      headCellEl.style.width || `${headCellElWidth}px`;

    clonedHeadCellEls[idx].style.minWidth =
      headCellEl.style.minWidth || `${headCellElWidth}px`;

    clonedHeadCellEls[idx].style.maxWidth =
      headCellEl.style.maxWidth || `${headCellElWidth}px`;
  });

  wrapperEl.style.marginTop = `-${tableHeadEl.offsetHeight}px`;
};

const tableResizeObserver = new ResizeObserver((entries) => {
  entries.forEach((entry) => {
    if (tableElRef.value !== entry.target) {
      return;
    }

    requestAnimationFrame(() => {
      recalcTableHeadCloneWidth();
    });
  });
});

watch(
  tableElRef,
  (val) => {
    if (!val) {
      return;
    }

    recalcTableHeadCloneWidth();

    tableResizeObserver.observe(val);
  },
  { immediate: true },
);

function onWrapperScroll(e: Event) {
  if (!wrapperCloneElRef.value || !(e.target instanceof HTMLElement)) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  wrapperCloneElRef.value.removeEventListener('scroll', onWrapperCloneScroll);
  wrapperCloneElRef.value.scrollLeft = e.target?.scrollLeft || 0;

  requestAnimationFrame(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    wrapperCloneElRef.value?.addEventListener('scroll', onWrapperCloneScroll);
  });
}

function onWrapperCloneScroll(e: Event) {
  if (!wrapperElRef.value || !(e.target instanceof HTMLElement)) {
    return;
  }

  wrapperElRef.value.removeEventListener('scroll', onWrapperScroll);
  wrapperElRef.value.scrollLeft = e.target?.scrollLeft || 0;

  requestAnimationFrame(() => {
    wrapperElRef.value?.addEventListener('scroll', onWrapperScroll);
  });
}

onMounted(() => {
  wrapperElRef.value?.addEventListener('scroll', onWrapperScroll);
  wrapperCloneElRef.value?.addEventListener('scroll', onWrapperCloneScroll);
});

const { rootClassNames } = useClassNames({
  name: 'ddh-table',
  classNames() {
    return ['text-body-2'];
  },
});

const classRowEven = (idx: number) => {
  return idx % 2 === 0 ? 'ddh-table__row--even' : '';
};

const genCell = (payload: {
  column: DdhTableColumn;
  className: DdhTableCell['className'];
  value: DdhTableCell['value'];
  colSpan: DdhTableCell['colSpan'];
  options?: {
    withoutPlaceholder?: boolean;
  };
}): DdhTableCell => {
  const { column, className, value, colSpan } = payload;
  const options = { ...payload.options };

  const style: DdhTableCell['style'] = {};

  if (column.width === 'auto') {
    style.width = '0';
  } else if (typeof column.width === 'number') {
    style.width = `${column.width}px`;
    style.minWidth = `${column.width}px`;
    style.maxWidth = `${column.width}px`;
  } else if (typeof column.width === 'string') {
    style.width = column.width;
    style.minWidth = column.width;
    style.maxWidth = column.width;
  }

  const type = column.type || DdhTableColumnType.Text;

  const title =
    typeof column.header.title === 'object'
      ? {
          content: column.header.title.content || '',
        }
      : {
          content: column.header.title || '',
        };

  const subtitle =
    typeof column.header.subtitle === 'object'
      ? {
          content: column.header.subtitle.content || '',
        }
      : {
          content: column.header.subtitle || '',
        };

  const description =
    typeof column.header.description === 'object'
      ? {
          width: column.header.description.width || undefined,
          content: column.header.description.content || '',
        }
      : {
          width: undefined,
          content: column.header.description || '',
        };

  const placeholder = options.withoutPlaceholder
    ? ''
    : replaceSpaceToNonBreakingSpace(
        column.placeholder || props.cellPlaceholder,
      );

  const sort = column.sort || false;

  let renderValue: DdhTableCell['renderValue'] = '';

  switch (type) {
    case DdhTableColumnType.Text: {
      if (value === '' || value === null || value === undefined) {
        renderValue = placeholder;
      } else {
        renderValue = value;
      }

      break;
    }

    case DdhTableColumnType.Number: {
      renderValue = numberFormat({ value, placeholder });

      break;
    }

    default: {
      renderValue = value || placeholder;

      break;
    }
  }

  const cell: DdhTableCell = {
    prop: column.prop,
    type,
    className,
    style,
    colSpan,
    title,
    subtitle,
    description,
    placeholder,
    sort,
    value,
    renderValue,
  };

  return cell;
};

const headerCells = computed(() => {
  const list = props.columns.reduce<DdhTableCell[]>((acc, column) => {
    const className = column.header.className || '';

    const cell = genCell({
      column,
      className,
      colSpan: 1,
      value: undefined,
      options: {
        withoutPlaceholder: true,
      },
    });

    acc.push(cell);

    return acc;
  }, []);

  return list;
});

const totalCells = computed(() => {
  let nextIdx = 0;

  const list = props.columns.reduce<DdhTableCell[]>((acc, column, idx) => {
    if (nextIdx !== idx) return acc;

    const className = column.total?.className || '';
    const value = column.total?.content ?? '';
    const colSpan = column.total?.colSpan || 1;

    nextIdx += colSpan;

    const cell = genCell({
      column,
      className,
      value,
      colSpan,
      options: {
        withoutPlaceholder: true,
      },
    });

    acc.push(cell);

    return acc;
  }, []);

  return list;
});

const totalIsShown = computed(() =>
  totalCells.value.some(
    (cell) =>
      cell.value !== '' && cell.value !== null && cell.value !== undefined,
  ),
);

const rows = computed(() => {
  const list = props.data.map((rowData) => {
    const cells = props.columns.reduce<DdhTableCell[]>((acc, column) => {
      const className = column.className || '';
      const value = get(rowData, column.prop);

      const cell = genCell({ column, className, colSpan: 1, value });

      acc.push(cell);

      return acc;
    }, []);

    const row = {
      data: rowData,
      cells,
    };

    return row;
  });

  return list;
});

const toggleSortingItem = (prop: DdhTableColumn['prop']) => {
  let newSortingItem: DdhTableSortingItem | null = null;

  if (props.sortingItem) {
    if (props.sortingItem.prop === prop) {
      newSortingItem = {
        prop,
        order: props.sortingItem.order === 'asc' ? 'desc' : 'asc',
      };
    } else {
      newSortingItem = { prop, order: props.defaultSortingOrder };
    }
  } else {
    newSortingItem = { prop, order: props.defaultSortingOrder };
  }

  emit('update:sorting-item', newSortingItem);
};

const onBottomIntersect = (isIntersecting: boolean) => {
  emit('bottomIntersect', isIntersecting);
};
</script>

<style lang="scss" scoped>
.ddh-table {
  --ddh-table-border: #{$table-border};

  background-color: var(--shades-white);

  &__wrapper {
    flex-grow: 0;
    flex-shrink: 1;
    overflow-x: auto;

    &--clone {
      position: sticky;
      top: v-bind(stickyOffsetCss);
      z-index: 1;
    }

    &--hidden-scroll {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__head {
    position: relative;

    &--original {
      opacity: 0;
    }
  }

  &__stub {
    min-height: 48px;
  }

  :deep {
    .ddh-table {
      &__row {
        background-color: var(--shades-white);

        &--even {
          background-color: var(--grey-lighten-5);
        }

        &:last-child {
          td,
          th {
            border-bottom: var(--ddh-table-border);
          }
        }
      }

      &__cell {
        &--body {
          overflow-wrap: break-word;
        }

        &--head {
          position: relative;
          user-select: initial !important;
        }

        &--high {
          height: v-bind(cssCellHeight) !important;
        }
      }

      &__icon-sort {
        fill: var(--grey-lighten-3);

        &--active {
          fill: var(--grey-darken-1);
        }
      }
    }
  }
}
</style>
